import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router'
import { ProductModel } from '../../../common/models/productModel'
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginComponent } from '../../components/login/login.component';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { UserCartDetailDto } from '../../../common/models/userCartDetailDto';
import { UserModel } from '../../../common/models/user-model';
import { carouselElement } from '../product-tile-carousel/carousel/carousel.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { CartModel } from '../../../common/models/cartModel';
import { CartService } from '../../../services/cart.service';
import { CartCountService } from '../../../services/cart-count.service';
import { TemplateConstants } from '../template-constants';

@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss']
})
export class ProductTileComponent implements OnInit, OnChanges {
  onProductTile: boolean;
  showCarousel: boolean = false;
  imagesList: Array<carouselElement> = [];
  priceRoundOffInteger: string = '1.2';
  allowWishlist: boolean = false;
  allowCart: boolean = false;
  authorName: string = "Marie";
  todaysDate: Date = new Date();
  showSaving: number;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loginDialogService: LsDialogService<LoginComponent>,
    private cartService: CartService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    private cartCountService: CartCountService
  ) {
    this.onProductTile = false;
  }

  @Input('tile-data') data: ProductModel = new ProductModel();
  @Output() addToCartWishlist: EventEmitter<any> = new EventEmitter<any>();
  @Output() addToCart: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  set view(viewVal: string) {
    this.onProductTile = (viewVal == "list");
  }
  prodData: ProductModel = new ProductModel()
  productName: string
  currType: string = "INR"
  target: string = ''
  sizeRec: string = ''
  variantData: Array<{ variantHeading: string, variantArr: Array<{ variantName: string, variantId: Array<number> }> }> = []
  defaultPath = TemplateConstants.defaultImgPath;
  ratingValue: number

  variantTypes: Array<{ variantHeading: string, options: Array<{ option: string, mappedUniqueIds: Array<number> }> }> = []
  variants: Array<ProductModel> = new Array()
  showVariants: boolean = false
  defaultClass: boolean = true

  currUser: UserModel
  cartProductMdel = new UserCartDetailDto();
  currUserEmail
  currTitleId
  currVariantId

  queryParams
  isMobile: boolean = false
  isDesktop: boolean = true
  isTab: boolean = false;
  carouselTimeOut = 1500;
  imageMap: Array<{ varId: string, images: Array<string> }> = [];
  selectedFilterIdx: number = 0;
  showVarImg: boolean = false;
  varImageUrl: string;

  ngOnInit() {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(window.navigator.userAgent)) {
      this.isMobile = true
      this.isTab = false
      this.isDesktop = false
    } else if (/Mobi|Tablet|iPad|kindle/i.test(window.navigator.userAgent)) {
      this.isMobile = false
      this.isTab = true
      this.isDesktop = false
    } else {
      this.isMobile = false
      this.isTab = false
      this.isDesktop = true
    }
    if (this.data) {
      if (this.data.imageMap) {
        Object.keys(this.data.imageMap).forEach((ele) => {
          let varId;
          varId = ele.split("_")[0];
          this.imageMap.push({
            varId: varId,
            images: this.data.imageMap[ele]
          });
        });
      }
      if (this.data.variantList && this.data.variantList.length > 0) {
        this.prodData = this.data.variantList[0]
      } else {
        this.prodData = this.data
      }
      this.productName = this.data.productName;
      this.showSaving = this.data.showSaving;
    }
    if (isPlatformBrowser(this.platformId)) {
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        this.currUserEmail = this.currUser.email;
      }
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
      if (Constants.ONLINE_STORE_SETTING_MODEL != undefined) {
        if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined) {
          if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1) {
            this.priceRoundOffInteger = '1.0'
          } else if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0) {
            this.priceRoundOffInteger = '1.2'
          }
        }
      }
      if (Constants.CART_SETTINGS != undefined) {
        if (Constants.CART_SETTINGS.allowCart != undefined) {
          if (Constants.CART_SETTINGS.allowCart == 1)
            this.allowCart = true;
          else if (Constants.CART_SETTINGS.allowCart == 0)
            this.allowCart = false;
        }
        if (Constants.CART_SETTINGS.allowWishList != undefined) {
          if (Constants.CART_SETTINGS.allowWishList == 1)
            this.allowWishlist = true;
          else if (Constants.CART_SETTINGS.allowWishList == 0)
            this.allowWishlist = false;
        }
      }
    }
  }

  ngOnChanges() {
    this.setUrl()
    this.getVariants()
    if (this.data != undefined) {
      this.variants = this.data.variantList
      this.ratingValue = this.data.productRatingAvg
      if (this.data.variantList != undefined && this.data.variantList.length > 0 && this.data.variantAttrMap) {
        this.showVariants = true
        let x = this.data.variantAttrMap[Object.keys(this.data.variantAttrMap)[0]]
        let y = x[Object.keys(x)[0]][0]
        let variant: ProductModel
        for (var index = 0; index < this.data.variantList.length; index++) {
          var element = this.data.variantList[index];
          if (element.id == y) {
            variant = this.data.variantList[index]
            break;
          }
        }
        if (variant) {
          if(variant.id)
            this.currVariantId = variant.id;
          if (variant.imageUrl != undefined && variant.imageUrl != "") {
            this.data.imageUrl = variant.imageUrl
          }
          if (variant.mrp != undefined) {
            this.data.mrp = variant.mrp
          }
          if (variant.salePrice != undefined) {
            this.data.salePrice = variant.salePrice
          }
        }
      }
    }

  }
  setUrl() {
    if (this.data != undefined) {
      let url: string = this.data.productURLText != undefined ? this.data.productURLText : ''
      this.target = 'details/' + url
      this.queryParams = { 't': this.data.titleId }
    }
  }

  getVariants() {
    if (this.data == undefined)
      return
    if (this.data.variantAttrMap != undefined) {
      let tempVariantArr = this.data.variantAttrMap;
      Object.keys(tempVariantArr).forEach(key => {
        let filters = tempVariantArr[key]
        let filtersArr: Array<{ option: string, mappedUniqueIds: Array<number> }> = []
        Object.keys(filters).forEach(element => {
          let idxArr: Array<number> = []
          filters[element].forEach(idxValue => {
            idxArr.push(idxValue);
          });
          filtersArr.push({
            option: element,
            mappedUniqueIds: idxArr
          })
        })
        this.variantTypes = new Array()
        this.variantTypes.push({
          variantHeading: key,
          options: filtersArr
        })
      })
      this.variantTypes.slice(0, 1)
    }
  }


  setProductVariant(event, variantId, idx) {
    this.defaultClass = false;
    this.selectedFilterIdx = idx;
    let variant: ProductModel = new ProductModel();
    this.variants.forEach(element => {
      if (element.id == variantId) {
        variant = element
      }
    });
    if (variant.imageUrl != undefined && variant.imageUrl != "") {
      this.data.imageUrl = variant.imageUrl
    }
    if (variant.mrp != undefined) {
      this.data.mrp = variant.mrp
    }
    if (variant.salePrice != undefined) {
      this.data.salePrice = variant.salePrice
    }
    this.queryParams = { 't': this.data.titleId, 'sizeRecNum': variant.id }
    this.currVariantId = variant.id;
    this.updateImagesByVarId();
  }

  updateImagesCarousel(images: Array<string>) {
    this.imagesList = [];
    if (this.data && this.data.imageUrl) {
      let prefixUrl = this.data.imageUrl;
      if (images && images.length > 1) {
        this.showVarImg = false;
        for (let i = 0; i < images.length; i++) {
          this.imagesList.push({ imageUrl: prefixUrl + 'm/' + images[i], title: '' });
        }
      } else if (images && images.length == 1) {
        this.varImageUrl = prefixUrl + 'm/' + images[0];
        this.showVarImg = true;
        this.showCarousel = false;
      }
    }
    if (!this.showCarousel && this.imagesList && this.imagesList.length > 1) {
      this.showCarousel = true;
      this.showVarImg = false;
    }
  }

  updateImagesByVarId() {
    let imgsFound: boolean = false;
    for (var index = 0; index < this.imageMap.length; index++) {
      var element = this.imageMap[index];
      if (element.varId == this.currVariantId) {
        imgsFound = true
        this.updateImagesCarousel(element.images);
        break;
      }
    }
    if (!imgsFound) {
      this.varImageUrl = this.defaultPath;
      this.showVarImg = true;
      this.showCarousel = false;
    }
  }

  mouseEnter() {
    if (!this.showCarousel && !this.isMobile) {
      if (this.data && this.data.variantList && this.data.variantList.length > 1) {
        if (this.data.imageMap) {
          if (this.imageMap && this.imageMap.length > 0) {
            this.updateImagesByVarId();
          }
        }
      } else {
        if (this.data && this.data.images && this.data.images.length > 0) {
          this.updateImagesCarousel(this.data.images);
        }
      }
    }
  }

  mouseLeave() {
    if (this.showCarousel && !this.isMobile) {
      this.imagesList = [];
      this.showCarousel = false;
    }
    if (!this.showCarousel && this.showVarImg) {
      this.showVarImg = false;
    }
  }

    addToWishlist() {     if(!isPlatformBrowser(this.platformId)) {       return    }
    if (window.localStorage.getItem('currentUser') !== null) {
      this.addToCartWishlistUtility();
    } else {
      this.loginDialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addToCartWishlistUtility();
        }
      });
    }
  }

  addToCartWishlistUtility() { if(!isPlatformBrowser(this.platformId)) {       return    }
    let msg = 'Added to your Wishlist!';
    let errMsg = 'Error in adding product to wishlist!';
    let localWishlistCart: CartModel;
    if (localStorage.getItem('wishlist')) {
      localWishlistCart = JSON.parse(localStorage.getItem('wishlist'));
    }
    let newProd: CartProductModel = new CartProductModel();
    newProd.titleId = Number.parseInt(this.data.titleId);
    newProd.numCopies = 1;
    let newWishlistCart: CartModel = new CartModel();
    newWishlistCart.cartProductModels = [];
    newWishlistCart.cartProductModels.push(newProd);
    newWishlistCart.cartWishListType = Constants.CART_WISHLIST_TYPE.WISHLIST;
    if (localWishlistCart && localWishlistCart.cartProductModels && localWishlistCart.cartProductModels.length > 0) {
      localWishlistCart.cartProductModels.forEach((ele: CartProductModel) => {
        newWishlistCart.cartProductModels.push(ele);
      });
    }
    if (!this.checkProductAlreadyPresent(parseInt(this.data.titleId), true)) {
      this.cartService.computeCart(newWishlistCart, (resp) => {
        if (!resp.error && resp.data && resp.data[0]) {
          this.setLocalCart(resp.data[0], true);
          this.cartCountService.updateWishlistCart(resp.data[0]);
          this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
        }
      });
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
    }
  }

  setLocalCart(cart: CartModel, isWishlist: boolean) {     if(!isPlatformBrowser(this.platformId)) {       return     }
    let newLocalCart: CartModel = new CartModel();
    if (cart && cart.cartProductModels && cart.cartProductModels.length > 0) {
      newLocalCart.cartProductModels = [];
      cart.cartProductModels.forEach((ele) => {
        if (!ele.offer) {
          let localCartEle: CartProductModel = new CartProductModel();
          if (ele.titleId)
            localCartEle.titleId = ele.titleId;
          if (ele.variantId)
            localCartEle.variantId = ele.variantId;
          if (ele.numCopies)
            localCartEle.numCopies = ele.numCopies;
          newLocalCart.cartProductModels.push(localCartEle);
        }
      });
    }
    if (!isWishlist) {
      localStorage.setItem('localCart', JSON.stringify(newLocalCart));
    } else {
      localStorage.setItem('wishlist', JSON.stringify(newLocalCart));
    }
  }

 checkProductAlreadyPresent(id: number, isWishlist: boolean): boolean {     if(!isPlatformBrowser(this.platformId)) {       return     }
    let localCart;
    if (isWishlist) {
      localCart = JSON.parse(localStorage.getItem('wishlist'));
    } else {
      localCart = JSON.parse(localStorage.getItem('localCart'));
    }
    let present: boolean;
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      for (let index = 0; index < localCart.cartProductModels.length; index++) {
        var element = localCart.cartProductModels[index];
        if (element.titleId == id) {
          present = true;
          break;
        }
      }
    }
    if (present) {
      return true;
    } else {
      return false;
    }
  }

  addToCartProduct() {
    this.addToCart.emit(this.data.titleId);
  }

  addPreOrderProduct(titleId: number){
    if (window.localStorage.getItem('currentUser') !== null) {
      this.addPreOrderUtility(titleId);
    } else {
      this.loginDialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
        if (window.localStorage.getItem('currentUser') !== null) {
          this.addPreOrderUtility(titleId);
        }
      });
    }
  }

  addPreOrderUtility(titleId: number){
    let errMsg = 'Some error occurred!';
    if(titleId){
      this.cartService.addPreOrder(titleId, (resp) => {
        if(!resp.error){
          this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]);
        }else{
          this.okayMessageDialogService.open(OkayMessageComponent, {}, errMsg);          
        }
      });
    }else{
      this.okayMessageDialogService.open(OkayMessageComponent, {}, errMsg);
    }
  }

  imgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}
