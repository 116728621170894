<header *ngIf="!checkOutVisibility" class="defaultWidth">
  <div fxLayout="column" class="header-column desktop-layout">
    <div class="header-top">
      <div class="vendorDetails" [ngClass]="{'contract-width': !bannerVisibility}">
        <div id="logo" class="col-xs-4">
          <a [routerLink]="''" style="height:70px; width:100%; position:relative;" *ngIf="companyDetails">
            <img class="center-position" [src]="companyDetails?.storeLogo ?companyDetails?.storeLogo:defaultPath "
              (error)="uploadDefaultLogoImage($event)" title="{{companyDetails?.storeName}}" alt="{{companyDetails?.storeName}}">
          </a>
        </div>
        <div class="pnlOrgName col-xs-8">
          <p class="lblOrgName">{{companyDetails?.storeName}}</p>
        </div>
      </div>
      <div class="userLinksOuter">
        <div class="userLinks col-xs-12 noPadding">
          <div class="pnlHdrDtls  col-xs-8 noPadding">
            <div class="pnlLoginDtls" *ngIf="loggedIn">
              <p id="lblUserName" class="lblUserName" [innerHTML]="userState.userName !=null ? 'Hello '+ userState.userName + '!!' : 'Hello '+userState.fName + '!!'"></p>
            </div>
            <div class="pnlHdrLinks">
              <a class="login" *ngIf="!loggedIn" class="login" (click)="onOpen()">Login</a>
              <a class="track-order" *ngIf="!loggedIn && !guestLoggedIn" (click)="showTrackPopOver()">Track Order</a>
              <a class="login" *ngIf="loggedIn" class="login" (click)="logOut()">Logout</a>
              <a *ngIf="loggedIn" routerLink="my-dashboard" routerLinkActive="active">Dashboard</a>
              <a *ngIf="loggedIn && allowWishlist" routerLink="my-dashboard/wishlist" routerLinkActive="active">Wishlist({{wishlistCount}})</a>
            </div>
          </div>
          <div class="pnlContactOuter col-xs-4" [ngClass]="{'hide': !allowCart}">
            <div class="pnlCartAmnt col-xs-8">
              <p class="lblMyBag">{{lblMyBag}}</p>
              <!-- <div class="pnlCartPrice cols-xs-12">
                <span class="lblHdrPrice">{{cartAmount | currency:currType:'symbol': priceRoundOffInteger}}</span>
              </div> -->
            </div>
            <div class="divCart col-xs-3" (click)="openCart()">
              <img src="{{shoppingBagImgSrc}}">
              <p class="cart-count">{{cartCount}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="navOuter" *ngIf="!bannerVisibility">
        <div class="navContainer">
          <div class="pnlNavBarToggle" [ngClass]="{'pnlNavBarExpanded': showBannerWrapper}" (mouseover)="showBanner()" (mouseout)="hideBanner()">
            <span class="fa fa-navicon"></span>
          </div>
        </div>
      </div>
      <!-- <div class="heading-tag col-xs-3">
        <div *ngIf="currencyList != undefined" fxLayout="column" class="currency-combo">
          <div class="selected-div" (click)="showCurrencyList(currencyOption)">
            <span [id]="currencyList[selectedCurrencyIndex]?.currencyCode" class="currency-span"></span>
            <a class="currency-value"> {{currencyList[selectedCurrencyIndex]?.currencyCode}}</a>
          </div>
          <ul class="currency-option" #currencyOption>
            <li *ngFor="let model of currencyList; let idx = index" class="currency-li" (click)="updateCurrency( idx,currencyOption )">
              <span [id]="model.currencyCode" class="currency-span"></span>
              <a class="currency-value">{{model?.currencyCode}}</a>
            </li>
          </ul>
        </div>
        <div class="right-pnl">
          <div id="trackDtls" *ngIf="!loggedIn && !guestLoggedIn">
            <p class="track-order" (click)="showTrackPopOver()">Track Order</p>
          </div>
          <div *ngIf="loggedIn" id="pnlLoginDtls" class="displayLoginDtls">
            <p id="lblUserName" class="lblUserName" [innerHTML]="userState.userName !=null ? 'Hello '+ userState.userName + '!!' : 'Hello '+userState.fName + '!!'"></p>
            <span class="fa fa-caret-down"></span>
            <div id="dashOptionPnl" tabindex="1" *ngIf="!guestLoggedIn">
              <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/profile" routerLinkActive="active">My Profile</a>
              <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/orders" routerLinkActive="active">My Orders</a>
              <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/transactions" routerLinkActive="active">Transaction
                Log</a>
              <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/change-password" routerLinkActive="active">Change
                Password</a>
              <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/wishlist" routerLinkActive="active">My Wishlist</a>
            </div>
          </div>
          <div *ngIf="!loggedIn" id="pnlLoginDtls">
            <p id="lblUserName" class="lblUserName" [innerHTML]="'Welcome Guest'"></p>
          </div>
        </div>
      </div> -->
    </div>
    <div class="banner-wrapper" [ngClass]="{'banner-hover': !bannerVisibility, 'show-banner': showBannerWrapper}" (mouseover)="showBanner()" (mouseout)="hideBanner()" >
      <div class="banner" *ngIf="bannerVisibility">
        <app-banner></app-banner>
      </div>
      <div class="category">
        <!-- <p class="categories" fxShow.gt-xs fxHide.lt-sm>All Categories</p> -->
        <div class="fetch-categories">
          <ul *ngIf="categoryList != undefined">
            <li *ngFor="let category of categoryList">
              <div id="itrCat-0">
                <a attr.categoryid={{category?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{category?.categoryURL}}">
                  <h1>{{category?.categoryName}}</h1>
                </a>
                <ul *ngIf="category?.categoryModels != undefined" class="itrCat">
                  <li *ngFor="let subCat of category?.categoryModels">
                    <div id="itrCat-1">
                      <a attr.categoryid={{subCat?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{subCat?.categoryURL}}"
                        style="position:relative">
                        {{subCat?.categoryName}}
                        <!-- <span *ngIf="subCat?.categoryModels != undefined" class="arrow-upleft fa fa-lg fa-angle-right"
                          style="position: absolute;right: 9px;font-weight: bold;"></span> -->
                      </a>
                      <ul *ngIf="subCat?.categoryModels != undefined" class="secCat">
                        <li *ngFor="let secLevel of subCat?.categoryModels" class="secLevel">
                          <div id="itrCat-2">
                            <a attr.categoryid={{secLevel?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{secLevel?.categoryURL}}">
                              {{secLevel?.categoryName}}
                            </a>
                            <ul *ngIf="secLevel?.categoryModels != undefined" class="thirdCat">
                              <li *ngFor="let thirdLevel of secLevel?.categoryModels" class="thirdLevel">
                                <div id="itrCat-3">
                                  <a attr.categoryid={{thirdLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                    routerLink="collections/{{thirdLevel?.categoryURL}}">
                                    {{thirdLevel?.categoryName}}
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="search-panel col-xs-12">
        <app-search></app-search>
      </div>
      <!-- <div class="pnlCartOuter col-xs-2">
        <div class="cart" (click)="openCart()">
          <span class="fa fa-shopping-cart"></span>
          <div class="cartCntPnl">
            <p class="cart-count">{{cartCount}}</p>
          </div>
        </div>
        <div class="pnlSignInHdr">
          <p class="login" *ngIf="!loggedIn" class="login" (click)="onOpen()">Sign In</p>
          <p class="login" *ngIf="loggedIn" class="login" (click)="logOut()">Sign Out</p>
        </div>
      </div> -->
    </div>
  </div>
  <!-- <div fxShow.lt-sm fxHide.gt-xs fxLayout="column" style="background-color:#f5f5f5;" class="mobile-layout">
    <div class="tagline" fxLayout="column" fxFlex="100">
      <p fxLayout="column" fxFlex="100" class="tagline">{{companyDetails?.storeTagLine}}</p>
    </div>
    <div fxLayout="row" style="border-top:5px solid #04582d; height:70px;">
      <div fxFlex="14" class="nav-panel" (click)="showCategories()"><span class="nav-bar"> </span></div>
      <div fxFlex="28" style="height:100%" class="img-logo" style="position:relative" (click)="navigateToHome()">
        <img src="{{companyDetails?.storeLogo}}" onerror="uploadDefaultLogoImage($event)" title="{{companyDetails?.storeName}}"
          alt="{{companyDetails.storeName}}" style="max-height:100%; max-width:100%;">
      </div>
      <div class="user-info" fxFlex="57" fxLayout="row" fxLayoutAlign="end end">
        <div class="track-panel" fxFlex="20" (click)="showTrackPopOver()"><span class="track-mob"></span></div>
        <div class="cart-panel" fxFlex="20" (click)="openCart()"><span class="cart-mob"></span><span class="cart-count">{{cartCount}}</span></div>
      </div>
    </div>
    <div fxLayout="row" class="search-panel">
      <app-search></app-search>
    </div>
  </div> -->
</header>