import { Component, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CartService } from '../../../services/cart.service';
import { CartCountService } from '../../../services/cart-count.service';
import { CartModel } from '../../../common/models/cartModel';
import { YesNoPopupComponent } from '../../commonComponent/yes-no-popup/yes-no-popup.component';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { Constants } from '../../../common/constants/lsnetx.constants'
import { Router } from '@angular/router';
import { LoaderService } from '../../../services/loader.service';
import { UserService } from '../../../services/user.service';
import { UserModel } from '../../../common/models/user-model';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { CouponDTO } from '../../../common/models/couponDto';
import { TemplateConstants } from '../../commonComponent/template-constants';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [CartService]
})
export class CartComponent implements OnDestroy {

  cart: CartModel = new CartModel();
  cartProducts: Array<CartProductModel> = new Array();
  currType: string = "INR";
  totalSavings: number = 0;
  grandTotal: number = 0;
  taxes: number = 0;
  shippingCharges: number = 0;
  handlingCharges: number = 0;
  cartNum: number = 0;
  cartCount: number = 0;
  currUser;
  emptyCart: boolean = false;
  isWishlist: boolean = false;
  dialog: LsMdDialog<CartComponent>;
  enquireVisibility: boolean = false;
  priceRoundOffInteger: string = '1.2';
  userState: UserModel;
  loggedIn: boolean = false;
  _userSubscription;
  couponApplied: string;
  couponAmount: number = 0;
  shippingDays: number = Constants.DEFAULT_SHIPPING_DAYS;
  defaultPath = TemplateConstants.defaultImgPath;
  goShoppingImgPath = TemplateConstants.templateAssetsPath + "/images/Go-SHopping.png";
  whiteCartImgPath = TemplateConstants.templateAssetsPath + '/images/whiteCart.png';
  _cartCountSubscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cartService: CartService,
    public YesNoDialogService: LsDialogService<YesNoPopupComponent>,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
    private router: Router,
    private cartCountService: CartCountService,
    private loaderService: LoaderService,
    private userService: UserService
  ) {

  }


  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
      }
      /**
      * check if user is logged in or not
      */
      this._userSubscription = this.userService.userStateModel.subscribe((userModel) => {
        this.userState = userModel;
        if (Object.keys(userModel).length != 0) {
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      });
      this.getUserCart();
      this.getCartCount();
      if (Constants.ONLINE_STORE_SETTING_MODEL.integrationDTO.crmServiceUrl != undefined && Constants.ONLINE_STORE_SETTING_MODEL.integrationDTO.crmServiceUrl != '') {
        this.enquireVisibility = true;
      }
      if (window.sessionStorage.getItem('currencyId') != null) {
        let currId = JSON.parse(window.sessionStorage.getItem('currencyId'));
        let currMap = JSON.parse(window.sessionStorage.getItem('currencyMap'));
        currMap.forEach(element => {
          if (element.id == currId) {
            this.currType = element.code;
          }
        });
      }
      if (Constants.ONLINE_STORE_SETTING_MODEL != undefined) {
        if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined) {
          if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1) {
            this.priceRoundOffInteger = '1.0';
          } else if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0) {
            this.priceRoundOffInteger = '1.2';
          }
        }
      }
    }
  }

  ngOnDestroy() {
    if (this._userSubscription) {
      this._userSubscription.unsubscribe();
    }
    if(this._cartCountSubscription) {
      this._cartCountSubscription.unsubscribe();
    }
  }

  getCartCount() {     if(!isPlatformBrowser(this.platformId)) {       return     }
    this._cartCountSubscription = this.cartCountService.cart.subscribe((cart) => {
      let currCart = cart;
      if (currCart && JSON.stringify(currCart) != '{}') {
        if (currCart.cartProductModels && currCart.cartProductModels.length > 0) {
          /**
           * check for offer product
           */
          let count = 0;
          currCart.cartProductModels.forEach((ele) => {
            if (!ele.offer) {
              count++;
            }
          });
          this.cartCount = count;
        } else {
          this.cartCount = 0;
        }
      } else {
        if (JSON.parse(localStorage.getItem('localCart'))) {
          let localCart = JSON.parse(localStorage.getItem('localCart'));
          if (localCart.cartProductModels && localCart.cartProductModels.length > 0) {
            /**
           * check for offer product
           */
            let count = 0;
            localCart.cartProductModels.forEach((ele) => {
              if (!ele.offer) {
                count++;
              }
            });
            this.cartCount = count;
          } else {
            this.cartCount = 0;
          }
        } else {
          this.cartCount = 0;
        }
      }
    });
  }

   removeLocalCart() {     if(!isPlatformBrowser(this.platformId)) {       return     }
    localStorage.removeItem('localCart');
    this.cartCountService.updateCart(new CartModel());
  }

  getAdditionalShippingDays(shippingDays: number): number {
    return shippingDays + Constants.PRODUCT_SHIPPING_DAYS_ADDITION;
  }

  getUserCart() {   if(!isPlatformBrowser(this.platformId)) {       return     }
    let validateOffers: boolean = true;
    let forceFetch: boolean;
    let cart = new CartModel();
    cart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
    if (this.loggedIn) {
      forceFetch = true;
    } else {
      forceFetch = false;
      cart.cartProductModels = [];
      let localCart: CartModel = JSON.parse(localStorage.getItem('localCart'));
      if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
        localCart.cartProductModels.forEach(element => {
          let cartProductModel: CartProductModel = new CartProductModel();
          cartProductModel.titleId = element.titleId;
          cartProductModel.variantId = element.variantId;
          cartProductModel.numCopies = element.numCopies;
          cart.cartProductModels.push(cartProductModel);
        });
      }
    }
    this.cartService.getCart(cart, validateOffers, forceFetch, (data) => {
      if (!data.error) {
        if (data.data == null) {
          this.emptyCart = true;
          this.removeLocalCart();
          return;
        } else {
          this.populateCart(data.data[0], false, null);
          if (this.currUser) {
            if (this.currUser.userType == Constants.UserType.guest) {
            } else {
              this.populateCoupons();
            }
          }
        }
      } else {
        this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error fetching cart!');
      }
    });
  }

  populateCart(cart: CartModel, isUpdate: boolean, cartProduct: CartProductModel) {
    if (cart) {
      this.cart = cart;
      this.cartProducts = [];
      if (this.cart.cartProductModels && this.cart.cartProductModels.length > 0) {
        this.cart.cartProductModels.forEach((ele) => {
          if (!ele.offer) {
            this.cartProducts.push(ele);
          }
        });
        this.cart.cartProductModels = this.cartProducts;
      } else {
        this.emptyCart = true;
        this.removeLocalCart();
      }
    } else {
      this.emptyCart = true;
      this.removeLocalCart();
    }
    if (!this.loggedIn) {
      this.calculatePricesForLocalCart();
    } else {
      this.calculatePrices();
    }
  }

  private showLoader() {
    this.loaderService.show();
  }

  private hideLoader() {
    this.loaderService.hide();
  }

  onClose() {
    this.dialog.close(null);//fns to close dialog
  }

  setVariantName(obj) {
    let a: string = obj[Object.keys(obj)[0]]
    return a

  }

  increaseProQuantity(cartProduct, idx: number) {
    if (cartProduct.numCopies < cartProduct.productModel.maxOrderQty) {
      this.cart.cartProductModels[idx].numCopies++;
      this.updateCart(cartProduct, idx);
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Maximum quantity reached');
    }
  }

  decreaseProQuantity(cartProduct, idx: number) {
    if (cartProduct.numCopies == 1) {
      this.removeProduct(cartProduct, idx);
      return;
    }
    if (cartProduct.numCopies > cartProduct.productModel.minOrderQty && cartProduct.numCopies > 1) {
      this.cart.cartProductModels[idx].numCopies--;
      this.updateCart(cartProduct, idx);
    } else {
      this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Minimum quantity reached');
    }
  }

  //updated
  updateCart(cartProduct: CartProductModel, idx: number) {
    if (this.loggedIn) {
      this.cartService.computeCart(this.cart, (data) => {
        if (!data.error) {
          if (data.data && data.data[0]) {
            this.cartProducts = [];
            this.cart = data.data[0];
            if (this.cart.cartProductModels && this.cart.cartProductModels.length > 0) {
              this.cart.cartProductModels.forEach((ele) => {
                if (!ele.offer) {
                  this.cartProducts.push(ele);
                }
              });
              this.updateLocalCart(cartProduct);
              this.calculatePrices();
              this.cart.cartProductModels = this.cartProducts;
            } else {
              this.emptyCart = true;
              this.removeLocalCart();
            }
          } else {
            this.emptyCart = true;
            this.removeLocalCart();
          }
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error in cart updation!');
        }
      });
    } else {
      /**
       * calcaulate prices for local cart
       */
      this.calculatePricesForLocalCart();
      this.updateLocalCart(cartProduct);
    }
  }

  updateLocalCart(cartProduct: CartProductModel) { if(!isPlatformBrowser(this.platformId)) {       return     }
    var localCart = JSON.parse(localStorage.getItem('localCart'));
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      localCart.cartProductModels.forEach(element => {
        if (element.titleId == cartProduct.titleId) {
          if (element.variantId && cartProduct.productModel.id) {
            if (element.variantId == cartProduct.productModel.id) {
              element.numCopies = cartProduct.numCopies;
            }
          } else {
            element.numCopies = cartProduct.numCopies;
          }
        }
      });
      localStorage.setItem("localCart", JSON.stringify(localCart));
    }
  }

  removeFromLocalCart(cartProduct: CartProductModel) { if(!isPlatformBrowser(this.platformId)) {       return     }
    var localCart: CartModel = JSON.parse(localStorage.getItem('localCart'));
    localCart.cartProductModels.forEach(element => {
      if (element.titleId == cartProduct.titleId) {
        if (element.variantId && cartProduct.productModel.id) {
          if (element.variantId == cartProduct.productModel.id) {
            localCart.cartProductModels.splice(localCart.cartProductModels.indexOf(element), 1);
          }
        } else {
          localCart.cartProductModels.splice(localCart.cartProductModels.indexOf(element), 1);
        }
      }
    });
    localStorage.setItem("localCart", JSON.stringify(localCart));
  }

  //updated
  removeProduct(cartProduct: CartProductModel, idx: number) {
    this.YesNoDialogService.open(YesNoPopupComponent, {}, "Are you sure you want to remove this product from your bag?").subscribe(response => {
      if (response == 'yes') {
        if (!this.loggedIn) {
          this.removeFromLocalCart(cartProduct);
          this.cart.cartProductModels.splice(idx, 1);
          this.populateCart(this.cart, true, cartProduct);
          if (this.cart.cartProductModels.length == 0) {
            this.emptyCart = true;
            this.removeLocalCart();
          }
        } else {
          let currCart = this.cart;
          currCart.cartProductModels.splice(idx, 1);
          this.cartService.computeCart(currCart, (resp) => {
            if (!resp.error) {
              if (resp.data && resp.data[0]) {
                this.removeFromLocalCart(cartProduct);
                this.populateCart(resp.data[0], true, cartProduct);
              } else {
                this.emptyCart = true;
                this.removeLocalCart();
              }
            } else {
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error removing product from cart!');
            }
          });
        }
      }
    });
  }

  proceedToCheckout() {
    let error: boolean = false;
    if (Constants.CART_SETTINGS.maxCartAmount != undefined) {
      if (this.grandTotal > Constants.CART_SETTINGS.maxCartAmount) {
        error = true;
        this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Maximum Cart Amount is :' + Constants.CART_SETTINGS.maxCartAmount)
      } 
    } 
    if (!error && Constants.CART_SETTINGS.maxQtyOfAnItemInCart != undefined) {
      if (this.cartNum > Constants.CART_SETTINGS.maxQtyOfAnItemInCart) {
        error = true;
        this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Maximum Cart Item Count is :' + Constants.CART_SETTINGS.maxQtyOfAnItemInCart)
      } 
    } 
    if(!error){
      this.dialog.close(null);
      this.router.navigate(['checkout']);      
    }
  }

  enquiryClick() {
    this.onClose();
  }

  calculateSubTotal(product: CartProductModel): number {
    let subTotal: number = 0;
    subTotal = (product.productModel.salePrice + product.productModel.gst + product.productModel.shippingCharges) * product.numCopies +
      product.productModel.handlingCharges;
    return subTotal;
  }

  calculatePricesForLocalCart() {
    let taxes = 0, gst = 0, shippingCharges = 0, handlingCharges = 0, totalSavings = 0, offerAmount = 0, totalAmount = 0;
    if (this.cart && this.cart.cartProductModels && this.cart.cartProductModels.length > 0) {
      this.cart.cartProductModels.forEach((ele: CartProductModel) => {
        if (ele.productModel) {
          taxes += ele.productModel.gst * ele.numCopies;
          gst += ele.productModel.gst * ele.numCopies;
          shippingCharges += ele.productModel.shippingCharges * ele.numCopies;
          handlingCharges += ele.productModel.handlingCharges;
          totalSavings += ele.productModel.discount * ele.numCopies;
          offerAmount += ele.offerAmount;
          totalAmount += (ele.productModel.salePrice + ele.productModel.gst + ele.productModel.shippingCharges)
            * ele.numCopies + ele.productModel.handlingCharges;
        }
      });
    }
    this.cart.totalAmount = totalAmount;
    this.cart.totalSavings = totalSavings;
    this.cart.taxes = taxes;
    this.cart.shippingCharges = shippingCharges;
    this.cart.handlingCharges = handlingCharges;
    this.cart.offerAmount = offerAmount;
    this.calculatePrices();
  }

  //updated
  calculatePrices() {
    this.grandTotal = this.cart.totalAmount;
    this.totalSavings = this.cart.totalSavings;
    this.taxes = this.cart.taxes;
    this.shippingCharges = this.cart.shippingCharges;
    this.handlingCharges = this.cart.handlingCharges;
    this.cartNum = this.cart.cartNum;
    this.couponAmount = this.cart.offerAmount;
    this.cartCountService.updateCart(this.cart);
  }

  couponList: Array<CouponDTO> = [];

  populateCoupons() {
    let fetchEnquiries: boolean = false;
    this.cartService.getValidCoupons(fetchEnquiries, (resp) => {
      if (!resp.error) {
        if (resp.data) {
          let coupons: Array<CouponDTO> = [];
          coupons = resp.data;
          if (coupons.length > 0) {
            coupons.forEach(element => {
              if (element.offerId) {
                this.couponList.push(element);
              }
            });
          }
          if (this.couponList.length > 1) {
            this.setDefaultCoupon();
          }
        }
      }
    });
  }

  setDefaultCoupon() {
    if (this.cart.offerIds && this.cart.offerIds.length > 0) {
      this.cart.offerIds.forEach((offer) => {
        this.couponList.forEach((coupon) => {
          if (offer == coupon.offerId) {
            this.couponApplied = coupon.couponCode;
          } else {
            this.couponApplied = undefined;
          }
        });
      });
    }
  }

  openContactUs() {
    this.onClose();
    this.router.navigate(['/contact-us']);
  }

  imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

}