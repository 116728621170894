import { Component, OnInit } from '@angular/core';
import { LsMdDialog, LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { OnlineStoreModel } from '../../../common/models/online-store-model';
import { LsRadioModule } from '../../../LSNG/components/ls-radio/ls-radio.module';
import { LsRatingModule } from '../../../LSNG/components/ls-rating/ls-rating.module';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CustomerSurveyService } from '../../../services/customerSurvey.service'
import { CartCountService } from '../../../services/cart-count.service'
import { QuestionModel } from '../../../common/models/questionModel'
import { AnswerModel } from '../../../common/models/answerModel'
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component'

@Component({
  selector: 'app-customer-survey',
  templateUrl: './customer-survey.component.html',
  styleUrls: ['./customer-survey.component.scss']
})

export class CustomerSurveyComponent implements OnInit {

  optionList: Array<string> = [];
  surveyForm: FormGroup;
  public dialog: LsMdDialog<CustomerSurveyComponent>;
  companyDetails: OnlineStoreModel;
  surveyQuestions: Array<QuestionModel> = [];
  surveyQuestion0: QuestionModel;
  surveyQuestion1: QuestionModel;
  surveyQuestion2: QuestionModel;
  surveyQuestion3: QuestionModel;
  surveyQuestion0OptionMap: Array<{ optionId: string, option: string }> = [];
  surveyQuestion1OptionMap: Array<{ optionId: string, option: string }> = [];
  dataList;
  ratingValue: number = 3.2;

  constructor(
    public formBuilder: FormBuilder,
    private customerSurveyService: CustomerSurveyService,
    private cartCountService: CartCountService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>
  ) {

  }

  ngOnInit() {
    this.companyDetails = Constants.VENDOR_DETAILS;
    this.surveyForm = this.formBuilder.group({
      q1: [''],
      q2: [''],
      q2Other: [''],
      ratingValue: [0],
      q3: ['']
    });
    this.getQuestions();
  }

  getQuestions() {
    this.customerSurveyService.getQuestionsForSurvey(this.dataList[0].formId, (resp) => {
      if (resp != null && resp != undefined) {
        this.surveyQuestions = resp;
        if (this.surveyQuestions) {
          this.surveyQuestion0 = this.surveyQuestions[0];
          Object.keys(this.surveyQuestion0.optionMap).forEach(element => {
            if (element) {
              this.surveyQuestion0OptionMap.push({
                optionId: element,
                option: this.surveyQuestion0.optionMap[element]
              })
            }
          });
          this.surveyQuestion1 = this.surveyQuestions[1];
          Object.keys(this.surveyQuestion1.optionMap).forEach(element => {
            if (element) {
              this.surveyQuestion1OptionMap.push({
                optionId: element,
                option: this.surveyQuestion1.optionMap[element]
              })
            }
          })
          this.surveyQuestion2 = this.surveyQuestions[2];
          this.surveyQuestion3 = this.surveyQuestions[3];
        }
      }
    })
  }

  close(isFormSubmit: boolean) {
    if(isFormSubmit){
      this.dialog.close('yes');    
    }else{
      this.dialog.close(null);    
    }
  }

  answerMap: Object = new Object;

  surveySubmit(event) {
    this.cartCountService.surveyFormFillBehaviour.next(true)
    this.answerMap[this.surveyQuestion0.questionId] = this.surveyForm.value.q1
    let q2Ans: string[] = []
    q2Ans.push(this.surveyForm.value.q2)
    if (this.surveyForm.value.q2Other != '') {
      q2Ans.push(this.surveyForm.value.q2Other)
    }
    this.answerMap[this.surveyQuestion1.questionId] = q2Ans
    this.answerMap[this.surveyQuestion2.questionId] = this.surveyForm.value.ratingValue
    this.answerMap[this.surveyQuestion3.questionId] = this.surveyForm.value.q3
    let answerModel: AnswerModel = new AnswerModel()
    answerModel.answerMap = this.answerMap
    answerModel.formId = this.dataList[0].formId
    this.customerSurveyService.submitQuestionsForSurvey(answerModel, (resp) => {
      if (!resp.error) {
        this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Your Coupon Code is Generated : ' +resp.data).subscribe(response => {
          this.close(true);
        });
      } else {
        this.okayMessageDialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          this.close(false);
        });
      }
    })
  }
  
  imgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = Constants.ASSETS_PATH + '/defaultLogo.png';
  }

}
