<div *ngIf="prodData != undefined" class="tileWrapper" [class.showHoverDiv]="showVariants" [class.on-product-tile]="onProductTile"
  (mouseleave)="mouseLeave()" (mouseenter)="mouseEnter()">
  <a class="layer" [routerLink]="'/'+target" [queryParams]="queryParams">
  </a>
  <span class="fa fa-heart" (click)="addToWishlist()"></span>
  <div class="imageWrapper">
    <!-- <div class="addWishPanel fa fa-heart-o" (click)="addToWishlist()"></div> -->
    <a [routerLink]="'/'+target" [queryParams]="queryParams" style="height: calc(100% - 20px);">
      <img [src]="(prodData?.imageUrl && prodData?.images && prodData?.images[0])?prodData.imageUrl+'m/'+prodData?.images[0]:defaultPath "
        class="img-responsive" alt="" (error)="imgErrorHandler($event)">
    </a>
  </div>
  <a class="offer" *ngIf="data?.discountPercent && data?.discountPercent!=0">Offer</a>
  <div class="detailDiv">
    <p class="productName"> {{productName}}</p>
    <div class="ratingDiv" *ngIf="ratingValue > 0">
      <ls-rating [editable]=false name="ratingVal" [(ngModel)]="ratingValue" ></ls-rating>
    </div>
    <div class="priceDiv">
      <div class="discountPnl">
        <div class="productMrp" *ngIf="prodData?.mrp > prodData?.salePrice && showSaving == 1">
          <p class="value">{{prodData?.mrp | currency:currType:'symbol': priceRoundOffInteger}}</p>
        </div>
        <div class="discount" *ngIf="data?.discountPercent && data?.discountPercent!=0">
          <p class="value">
            {{data?.discountPercent}}%&nbsp;off
          </p>
        </div>
      </div>
      <div class="productSalePrice">
        <p>
          {{prodData?.salePrice | currency:currType:'symbol': priceRoundOffInteger}}
        </p>
      </div>
    </div>
  </div>
  <a [routerLink]="'/'+target" [queryParams]="queryParams" style="height: calc(100% - 20px);">
  </a>
  <!-- <div class="hoverDiv">
  <div class="pnlFilterItrOuter">
    <ul class="filterItr">
      <div class="filterItrLi" *ngFor="let variantType of variantTypes; let variantTypeIdx = index;">
        <div class="pnlMandtryFieldInner">
          <p class="variantDesc">{{variantType.variantHeading}}</p>
          <ul class="variantItr">
            <li class="variantItrLi" [class.filterSelected]="(optionIdx == selectedFilterIdx)||(optionIdx == 0 && defaultClass == true)"
              *ngFor="let variant of variantType.options; let optionIdx = index;" (click)="setProductVariant($event, variant.mappedUniqueIds, optionIdx)">
              <p class="filter">{{variant.option}}</p>
            </li>
          </ul>
        </div>
      </div>
    </ul>
  </div>
</div> -->
</div>