<div class="filters" *ngIf="filterList && filterList.length > 0">
  <h4 fxShow.gt-xs fxHide.lt-sm class="refine-heading">Refine By</h4>
  <ul class="filtersIterator">
    <li *ngFor="let filterModel of filterList; let i=index;">
      <div class="filterPanel" *ngIf="filterModel.filterModelList && filterModel.filterModelList.length > 0">            
        <div class="filterTypePanel" [attr.data-filtertypevalue]="filterModel.seqNo">
          <h5 class="filterTypeHeading">{{filterModel.filterName}}</h5>
          <span class="expColSpan colSpan fa" (click)="toggleDetails(i, $event)"></span>
        </div>
        <div class="typeSpecFilterPanel" id="typeSpecFilterPanel" [ngClass]="{'hide': showDetails[i], 'heightForHide': showDetails[i], 'heightForShow': !showDetails[i]}">
          <perfect-scrollbar>
          <ul *ngIf="filterModel.filterModelList != undefined" class="typeSpecFilterIterator" id="typeSpecFilterIterator">
            <li *ngFor="let model of filterModel.filterModelList; let idx = index;">
              <div *ngIf="model.filterDisplayValue != undefined" class="typeSpecCheckBox" [attr.data-filterTypeSpecVal]="model.seqNo">
                <input class="typeSpecCheckBox-selector" [(ngModel)] = "model.checked" [id]="'typeSpecCheckBox-selector--'+idx+i" type="checkbox" (change)="filterData($event,idx,i, filterModel.filterId, model.filterValueId)">                
                <label class="typeSpecCheckBox-label" [for]="'typeSpecCheckBox-selector--'+idx+i" [title]="model.filterDisplayValue" >{{model.filterDisplayValue}}</label>
              </div>
            </li>
          </ul>
        </perfect-scrollbar>
        </div>
      </div>
    </li>
  </ul>

  <div class="mob-apply-filters" fxShow.lt-sm fxHide.gt-xs (click)="applyFilters()" ><p class="apply-filters">Apply Filter</p></div>
</div>
