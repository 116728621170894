<div class="contact-us">
  <p class="heading">Contact Us</p>
  <div class="container">
    <div class="leftPnl col-xs-8">
      <div class="pnlContactTop" *ngIf="content && content != 'false'">
        <div [innerHtml]='content | safeHtml' class="static-content" *ngIf="content != 'false'"></div>
      </div>
      <div class="vender-info">
          <div class="contact-mail" *ngIf="vendorDetails != undefined" >
            <p class="sub-heading">Mail Us</p>
            <div class="pnlContactMailInner col-xs-12">
              <div class="pnlMailImg col-xs-1">
                <img class="center-position" [src]="emailIconPath">
              </div>
              <div class="contactusPnls col-xs-11"><a [href]="'mailto:'+vendorDetails.ccMail">{{vendorDetails.ccMail}}</a></div>
            </div>
          </div>
          <div class="contact-call">
            <p class="sub-heading">Call Us</p>
            <div class="pnlContactCallInner col-xs-12">
              <div class="pnlCallImg col-xs-1">
                  <img class="center-position" [src]="phoneIconPath">
              </div>
              <div class="col-xs-11 contactusPnls">
                  <p>{{vendorDetails.ccContactNo}}</p>
              </div>
            </div>
          </div>
          <div class="contact-office">
            <p class="sub-heading">Corporate Office</p>
            <div class="pnlContactOfficeInner col-xs-12">
                <div class="pnlOfficeImg col-xs-1">
                    <img class="center-position" [src]="locationIconPath">
                </div>
                <div class="col-xs-11 contactusPnls">
                    <p>{{vendorDetails.companyDTO.address}},{{vendorDetails.companyDTO.city}}</p>
                    <p>{{vendorDetails.companyDTO.address}}</p>
                    <p>{{vendorDetails.companyDTO.city}} - {{vendorDetails.companyDTO.postalCode}}</p>
                    <p>{{vendorDetails.companyDTO.state}} - {{vendorDetails.companyDTO.postalCode}}</p>
                    <p *ngIf="vendorDetails.companyDTO.countryDTO && vendorDetails.companyDTO.countryDTO.countryName">{{vendorDetails.companyDTO.countryDTO.countryName}}</p>
                </div>
            </div>
          </div>
        </div>
      <!-- <form enctype="multipart/form-data" [formGroup]="contactForm" (ngSubmit)="submitContactQuery()">
        <div class="form-field">
          <p class="label">Name</p>
          <input class="txtFieldCls" type="text" formControlName="name">
        </div>
        <div class="form-field">
          <p class="label">Subject</p>
          <select class="text-field txtFieldCls" formControlName="queryOption">
            <option *ngFor="let item of subjectList" [value]="item.value">{{item.label}}</option>
          </select>
        </div>
        <div class="form-field">
          <p class="label">Email</p>
          <input class="txtFieldCls" type="email" formControlName="email">
        </div>
        <div class="form-field">
          <p class="label">Contact Number</p>
          <input class="txtFieldCls" maxlength="10" (keypress)="keyPress($event)" pattern="[0-9]*" formControlName="phoneNum">
        </div>
        <div class="form-field">
          <p class="label">Message (max. 500 characters)</p>
          <textarea class="text-area txtFieldCls" formControlName="message"></textarea>
        </div>
        <div class="attch-file">
          <p #fileName></p>
          <span #changeMenu class="attchment"> + Add Attachment</span>
          <input type="file" class="file-upload" (change)="onFileChange($event, fileName, changeMenu)">
        </div>
        <div class="btnPnl">
          <button class="submit" [ngClass]="{'disable': !contactForm.valid}" [disabled]="!contactForm.valid">Send</button>
        </div>
      </form> -->
    </div>
    <div class="rightPnl col-xs-4">
      <!-- <div class="vender-info">
        <div *ngIf="vendorDetails != undefined" class="contact-mail">
          <p class="sub-heading">Mail Us</p>
          <a [href]="'mailto:'+vendorDetails.ccMail">{{vendorDetails.ccMail}}</a>
        </div>
        <div class="contact-call">
          <p class="sub-heading">Call Us</p>
          <p>{{vendorDetails.ccContactNo}}</p>
        </div>
        <div class="contact-office">
          <p class="sub-heading">Corporate Office</p>
          <p>{{vendorDetails.companyDTO.address}},{{vendorDetails.companyDTO.city}}</p>
          <p>{{vendorDetails.companyDTO.address}}</p>
          <p>{{vendorDetails.companyDTO.city}} - {{vendorDetails.companyDTO.postalCode}}</p>
          <p>{{vendorDetails.companyDTO.state}} - {{vendorDetails.companyDTO.postalCode}}</p>
          <p *ngIf="vendorDetails.companyDTO.countryDTO && vendorDetails.companyDTO.countryDTO.countryName">{{vendorDetails.companyDTO.countryDTO.countryName}}</p>
        </div>
      </div> -->
      <form enctype="multipart/form-data" [formGroup]="contactForm" (ngSubmit)="submitContactQuery()">
        <div class="form-field">
          <!-- <p class="label">Name</p> -->
          <input class="txtFieldCls" [placeholder]="'Name'" type="text" formControlName="name">
        </div>
        <div class="form-field">
          <!-- <p class="label">Subject</p> -->
          <select class="text-field txtFieldCls" formControlName="queryOption">
            <option *ngFor="let item of subjectList" [value]="item.value">{{item.label}}</option>
          </select>
        </div>
        <div class="form-field">
          <!-- <p class="label">Email</p> -->
          <input class="txtFieldCls" type="email" formControlName="email" [placeholder]="'Email'">
        </div>
        <div class="form-field">
          <!-- <p class="label">Contact Number</p> -->
          <input class="txtFieldCls" maxlength="10" (keypress)="keyPress($event)" pattern="[0-9]*" [placeholder]="'Contact Number'" formControlName="phoneNum">
        </div>
        <div class="form-field">
          <!-- <p class="label">Message (max. 500 characters)</p> -->
          <textarea class="text-area txtFieldCls" formControlName="message" [placeholder]="'Message (max. 500 characters)'"></textarea>
        </div>
        <div class="attch-file">
          <p #fileName></p>
          <span #changeMenu class="attchment"> + Add Attachment</span>
          <input type="file" class="file-upload" (change)="onFileChange($event, fileName, changeMenu)">
        </div>
        <div class="btnPnl">
          <button class="submit" [ngClass]="{'disable': !contactForm.valid}" [disabled]="!contactForm.valid">Send</button>
        </div>
      </form>
      <!-- <div class="contact-map" *ngIf="googleMapUrl">
        <iframe *ngIf="googleMapUrl" [attr.src]="googleMapUrl | safeUrl"></iframe>
      </div> -->
    </div>
  </div>
</div>