<!-- <loader></loader> -->
<mat-sidenav-container>
  <mat-sidenav ngClass="menu-width" #accountDashboard opened="false" mode="over">
    <app-category [currencyMap]="currMap" [categoryList]="catList"></app-category>
  </mat-sidenav>
  <div fxFlexFill [class]="master">
    <app-header *ngIf="headerVisibility" (currencyMapEvent)="currMapHandler($event)" (categoryListEvent)="catgListHandler($event)"></app-header>
    <app-breadcrumbs *ngIf="breadcrumbsVisibility"></app-breadcrumbs>
    <loader></loader>
    <div class="routerDiv defaultWidth" [ngClass]="{'dashboard-div':isDashboard,'pos': isPos}">
      <!-- <div class="categories-container" *ngIf="headerVisibility">
        <div class="fetch-categories" id="categoryContainer">
          <ul *ngIf="categoryList != undefined">
            <li *ngFor="let category of categoryList">
              <div id="itrCat-0">
                <a attr.categoryid={{category?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{category?.categoryURL}}">
                  {{category?.categoryName}}
                  <span *ngIf="category?.categoryModels != undefined" class="arrow-upleft fa fa-lg fa-angle-right"
                    style="position: absolute;right: 9px;font-weight: bold;display:none;"></span>
                </a>
                <ul *ngIf="category?.categoryModels != undefined" class="itrCat">
                  <li *ngFor="let subCat of category?.categoryModels">
                    <div id="itrCat-1">
                      <a attr.categoryid={{subCat?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{subCat?.categoryURL}}"
                        style="position:relative">
                        {{subCat?.categoryName}}
                      </a>
                      <ul *ngIf="subCat?.categoryModels != undefined" class="itrCat secCat">
                        <li *ngFor="let secLevel of subCat?.categoryModels" class="secLevel">
                          <div id="itrCat-2">
                            <a attr.categoryid={{secLevel?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{secLevel?.categoryURL}}">
                              {{secLevel?.categoryName}}
                            </a>
                            <ul *ngIf="secLevel?.categoryModels != undefined" class="itrCat thirdCat">
                              <li *ngFor="let thirdLevel of secLevel?.categoryModels" class="thirdLevel">
                                <div id="itrCat-3">
                                  <a attr.categoryid={{thirdLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                    routerLink="collections/{{thirdLevel?.categoryURL}}">
                                    {{thirdLevel?.categoryName}}
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div> -->
      <div class="router-container" [ngClass]="{'fullWidth': !headerVisibility}">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer *ngIf="headerVisibility"></app-footer>
    <a class="lnkTopArrow" (click)="scrollToTop()" *ngIf="showLnkTopArrow">
      <span class="fa fa-chevron-up"></span>
    </a>
  </div>
</mat-sidenav-container>