<footer *ngIf="!checkOutVisibility">
  <!-- <div>
    <div class="footer-mb defaultWidth">
      <div class="social-share-container">
        <div *ngIf="socialSettings != undefined" class="social-share">
          <p id="lbl">Engage with us</p>
          <div class="wrapper">
            <a *ngIf="socialSettings.fbPageURL!= ''" href={{socialSettings.fbPageURL}} class="facebook" title="Facebook"><span></span></a>
            <a *ngIf="socialSettings.googlePageURL!= ''" href={{socialSettings.googlePageURL}} class="gPlus" title="gPlus"><span></span></a>
            <a *ngIf="socialSettings.linkedInPageURL!= ''" href={{socialSettings.linkedInPageURL}} class="linkedin"
              title="LinkedIn"><span></span></a>
            <a *ngIf="socialSettings.twitterPageURL!= ''" href={{socialSettings.twitterPageURL}} class="twitter" title="Twitter"><span></span></a>
          </div>
        </div>
      </div>
      <div class="newsletter-container">
        <div *ngIf="showNewsletter" class="newsletter" fxLayout="row">
          <input type="email" id="subsEmail" placeholder="Subscribe to our newsletter" class="tfSubscriptionEmail"
            #inputVal />
          <p class="send-news okLbl" fxLayoutAlign="center center" (click)="subscribeNewsletter(error, inputVal)"><span
              class="fa fa-check"></span></p>
          <span #error class="hide errorLbl"></span>
        </div>
      </div>
    </div>
    <div class="footer-padding  defaultWidth">
      <div class="footer-lower">
        <div *ngIf="quickLinks != undefined" class="quicklinks">
          <p class="heading hide">QUICKLINKS</p>
          <ul>
            <li *ngFor="let quickLink of quickLinks">
              <a *ngIf="quickLink.pageURL.includes('http')" [href]="httpUrl" class="lnkStores" target="_blank">{{quickLink.linkName}}</a>
              <a *ngIf="!quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores">{{quickLink.linkName}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright" fxLayout="row">
    <p class="copyright-txt" fxFlex="70">{{copyRTxt}}</p>
    <div class="power-by" fxLayout="row" fxLayoutAlign="end end" fxFlex="30">
      <p class="power-txt">Powered By</p>
      <a href="http://www.lsnetx.com" target="_blank" class="copy-right-img"><img src="assets/logo.png" title="LSNetX"
          alt="LSNetX"></a>
    </div>
  </div> -->
  <div class="pnlFooter">
    <div class="staticFooterContent" *ngIf="!errorFlag">
      <div class="static-page">
        <p class="heading">{{heading}}</p>
        <div [innerHtml]='content | safeHtml' class="static-content" *ngIf="content != undefined"></div>
      </div>
      <div class="pnlStores">
        <p class="heading">Stores</p>
        <ul class="itrStores">
          <li class="floatedLeft" *ngFor="let category of categoryList">
            <div class="storeNames">
              <a routerLink="collections/{{category.categoryURL}}" class="lnkStores">{{category.categoryDisplayName}}</a>
            </div>
          </li>
        </ul>
      </div>
      <div class="pnlCopyRights">
          <p class="copyright-txt" [innerHtml]="copyRTxt | safeHtml"></p>
          <div class="power-by">
            <p class="power-txt">Powered By</p>
            <a href="http://www.lsnetx.com" target="_blank" class="copy-right-img"><img src="assets/logo.png" title="LSNetX" alt="LSNetX"></a>
          </div>
      </div> 
    </div>
    <!-- <div class="cardDetails"></div> -->
    <div class="quickLinks">
      <div class="footerPnl">
        <div class="pnlFooterStrip">
          <div class="footerLinks">
            <a class="lnkQck lnkQuick pull-left">Quick Links
              <span *ngIf="!showDownArrow" class="arrow arrowUp" (click)="showQuickLinksDiv()"></span>
              <span *ngIf="showDownArrow" class="arrow arrowDown" (click)="showQuickLinksDiv()"></span>
            </a>
            <a class="lnkQuick pull-left" (click)="openContact()">Contact Us</a>
            <div class="pnlSocialLnks pull-left" *ngIf="socialSettings != undefined">
              <ul class="itrSocialLnks">
                <li class="floatedLeft" *ngIf="socialSettings.fbPageURL!= ''">
                  <div id="facebook" class="socialLink">
                    <a href={{socialSettings.fbPageURL}} class="facebook" title="Facebook"></a>
                  </div>
                </li>
                <li class="floatedLeft" *ngIf="socialSettings.googlePageURL!= ''">
                  <div id="gplus" class="socialLink">
                    <a href={{socialSettings.googlePageURL}} class="gPlus" title="gPlus"></a>
                  </div>
                </li>
                <li class="floatedLeft" *ngIf="socialSettings.linkedInPageURL!= ''">
                  <div id="linkedIn" class="socialLink">
                    <a href={{socialSettings.linkedInPageURL}} class="linkedin" title="LinkedIn"></a>
                  </div>
                </li>
                <li class="floatedLeft" *ngIf="socialSettings.twitterPageURL!= ''">
                  <div id="twitter" class="socialLink">
                    <a href={{socialSettings.twitterPageURL}} class="twitter" title="Twitter"></a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="newsletter-container">
              <div *ngIf="showNewsletter" class="newsletter">
                <span #error class="hide errorLbl pull-left"></span>
                <p class="pull-left lblSubscribe">Subscribe to our Newsletter</p>
                <input type="email" id="subsEmail" placeholder="" class="tfSubscriptionEmail" #inputVal  (keydown)="subscribeNewsletter(error, inputVal,$event)"/>
                <!-- <p class="send-news okLbl" fxLayoutAlign="center center" (click)="subscribeNewsletter(error, inputVal)">
                    <span class="fa fa-check"></span>
                  </p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="pnlQuickDet" id="pnlQuickDet">
          <div class="quickLnkOuterPnl">
            <ul class="iterator quickLinkItr" *ngIf="quickLinks != undefined">
              <li class="quicklinks floatedLeft" *ngFor="let quickLink of quickLinks">
                <div class="quickLnkInnerPnl">
                  <a *ngIf="quickLink.pageURL.includes('http')" [href]="httpUrl" class="lnkStores" target="_blank">{{quickLink.linkName}}</a>
                  <a *ngIf="!quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores">{{quickLink.linkName}}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>